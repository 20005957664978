<template>
  <div>
    <section class="main">
      <div class="hero parallax"></div>

      <article>
        <h2>Get a FREE QUOTE today!</h2>

        <div class="mission-statement">
          We would love to hear from you!
        </div>

        <div class="flex-container">
          <div class="contact_form">
            <input type="text" v-model="form.full_name" id="full_name" placeholder="Your Name *" required @keypress.enter="submitForm" />
            <input type="text" v-model="form.city" id="city" placeholder="City *" required @keypress.enter="submitForm" />
            <input type="text" v-model="form.state" id="state" maxlength="2" placeholder="State *" required @keypress.enter="submitForm" />
            <input type="text" v-model="form.email" id="email" placeholder="Email *" required @keypress.enter="submitForm" />
            <input type="number" v-model="form.phone" id="phone" maxlength="10" placeholder="Phone *" required @keypress.enter="submitForm" />
            <textarea type="text" v-model="form.message" id="message" placeholder="How can we help? *" required></textarea>
            <v-btn
              dark
              x-large
              outlined
              id="submit"
              @click.stop="submitForm"
            >
              SUBMIT
            </v-btn>
          </div>
          <div class="locations">
            <div v-for="(state) in Object.keys($store.state.contact)" :key="'contact_' + state">
              <h5>{{ $store.state.contact[state].title }}</h5>
              {{ $store.state.contact[state].address1 }},<br />
              {{ $store.state.contact[state].city }}, {{ $store.state.contact[state].state }} {{ $store.state.contact[state].zip }}<br />
              Office: <a :href="'tel:' + $store.state.contact[state].phone">{{ $store.state.contact[state].phone }}</a><br />
              Fax: {{ $store.state.contact[state].fax }}<br />
              <a :href="'mailto:' + $store.state.contact[state].email">{{ $store.state.contact[state].email }}</a>
            </div>
          </div>
        </div>

      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    form: {
      full_name: '',
      city: '',
      state: '',
      email: '',
      phone: '',
      message: ''
    }
  }),
  methods: {
    submitForm: function () {
      console.log('Submitting!')
      window.axios.post('/forms/contact')
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped>
section.main div.mission-statement {
  font-style: italic;
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 30px;
}
section.main div.flex-container div.locations {
  width: 35%;
}
section.main div.flex-container div.locations div {
  margin-bottom: 50px;
  font-size: 1.2em;
  line-height: 1.6em;
}
section.main div.flex-container div.locations div h5{
  font-size: 1.3em;
  letter-spacing: 2px;
}
section.main div.flex-container div.locations div a[href^="tel:"] {
  font-weight: 900;
  border: solid 1px white;
  padding: 2px 8px;
  border-radius: 6px;
}
section.main div.flex-container div.contact_form {
  background-color: rgba(0,0,0,.5);
  border-radius: 15px;
  padding: 30px;
  width: 60%;
}
section.main div.flex-container div.contact_form input, textarea {
  background-color: rgba(255,255,255,.9);
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: 600;
  display: block;
}
section.main div.flex-container div.contact_form textarea:last-child {
  margin-bottom: 0;
}
section.main div.flex-container div.contact_form input[id="state"] {
  text-transform: uppercase;
}
input[id="full_name"], input[id="city"] {
  text-transform: capitalize;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#submit {
  float: right;
  font-size: 1.5em;
  font-weight: 600;
}
/* media queries */
@media screen and (max-width: 650px){
  section.main div.flex-container div.contact_form {
    width: 58%;
  }
  section.main div.flex-container div.locations {
    width: 38%;
  }
  section.main div.flex-container div.locations div {
    font-size: 1em;
  }
  section.main div.flex-container div.locations div h5 {
    font-size: 1em;
    letter-spacing: 0px;
  }
  section.main div.flex-container div.contact_form input, textarea {
    padding: 6px 12px;
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  #submit {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 570px){
  section.main div.flex-container div.contact_form {
    width: 55%;
  }
  section.main div.flex-container div.locations {
    width: 42%;
  }
  section.main div.flex-container div.locations div {
    font-size: .9em;
  }
  section.main div.flex-container div.contact_form {
    padding: 20px 15px;
  }
}
@media screen and (max-width: 470px){
  section.main div.mission-statement {
    text-align: center;
  }
  section.main div.flex-container div.contact_form {
    width: 100%;
  }
  section.main div.flex-container div.contact_form {
    padding: 30px;
  }
  section.main div.flex-container div.contact_form input, textarea {
    padding: 10px 20px;
    margin-bottom: 30px;
  }
  section.main div.flex-container div.locations {
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;
    font-size: 1.3em;
  }
  section.main div.flex-container div.locations div {
    margin-bottom: 10px;
  }
  section.main div.flex-container div.locations div::after {
    content: '';
    display: block;
    clear: both;
    margin-top: 10px;
    border-bottom: dashed 1px rgba(255,255,255,.5);
  }
  section.main div.flex-container div.locations div:last-child::after {
    content: '';
    border-bottom: 0;
  }
}
</style>
